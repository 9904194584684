import React from "react"
import { graphql, Link } from "gatsby"
import { getFluidGatsbyImage } from "../components/getFluidGatsbyImage"
import { documentToReactComponents } from "@contentful/rich-text-react-renderer"
import { BLOCKS } from "@contentful/rich-text-types"
import Img from "gatsby-image"
import SEO from "../components/seo"
import ScrollAnimation from "react-animate-on-scroll"
import { TemplateStyle } from "../styles/templateProj"

export const query = graphql`
  query($slug: String!) {
    contentfulCarouselProjects(slug: { eq: $slug }) {
      title
      publishedDate(formatString: "D [de] MMMM [de] YYYY", locale: "pt")
      blog {
        json
      }
      slug
      image {
        file {
          url
        }
      }
    }
    bg: dataJson {
      projectos {
        img {
          publicURL
        }
      }
    }
  }
`
const ProjTemplate = props => {
  const options = {
    renderNode: {
      [BLOCKS.EMBEDDED_ASSET]: node => {
        const { file, title } = node.data.target.fields
        const image = {
          file: file["pt"],
        }
        const fluidProps = getFluidGatsbyImage(image, { maxWidth: 720 })
        return <Img fluid={fluidProps} alt={title["pt"]} />
      },
    },
  }

  return (
    <>
      <SEO
        title={props.data.contentfulCarouselProjects.title}
        pathname={`/${props.data.contentfulCarouselProjects.slug}`}
        image={props.data.contentfulCarouselProjects.image.file.url}
      />
      <TemplateStyle>
        <div
          className="main-container"
          style={{
            backgroundImage: `url(${props.data.bg.projectos.img.publicURL})`,
          }}
        >
          <ScrollAnimation animateIn="fadeIn">
            <div className="blog-container">
              <Link to="/projetos">
                <h4 className="linkBack">
                  <i className="material-icons">arrow_back</i> Voltar a projetos
                </h4>
              </Link>
              <h1 className="title">
                {props.data.contentfulCarouselProjects.title}
              </h1>
              <p className="date">
                {props.data.contentfulCarouselProjects.publishedDate}
              </p>
              <div className="post">
                {documentToReactComponents(
                  props.data.contentfulCarouselProjects.blog.json,
                  options
                )}
              </div>
            </div>
          </ScrollAnimation>
        </div>
      </TemplateStyle>
    </>
  )
}

export default ProjTemplate
